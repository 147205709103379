/* You can add global styles to this file, and also import other style files */
@import './variables.scss';
/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */
 html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  /**
   * Firefox specific rule
   */
  @-moz-document url-prefix() {
    body {
      font-weight: lighter !important;
    }
  }
  

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.agrimaster-dialog-container .mat-dialog-container {
  padding: 0;
}

.notification-link {
  color: $primary !important;
}

.notification-link:visited{
  color: $primary !important;
}

.mat-expansion-panel-header-description, 
.mat-expansion-indicator::after {
    color: whitesmoke !important;
    border-color: whitesmoke !important;
}

.error-text {
  color: red;
}

.disclaimer-align {
  vertical-align:top;
}

.disclaimer-margin{
  margin-top:2px;
}

ul.disclaimer{
  list-style: disc;
}

.product-content-container {
  padding: 20px;
}

.product-container{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.container-button button{
  margin-left: 10px;
}